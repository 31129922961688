<template>
 <div class="box">
    <div class="box-header">
      <h4>{{ pageTitle }}</h4>
    </div>
    <shop-form v-if="product"
                :submit-text="pageTitle"
                :product="product"
                @on-submit="createProduct">
    </shop-form>
    <placeholder-form v-else></placeholder-form>
  </div>
</template>

<script>
import ShopForm from './components/_EditForm'
import ShopTempService from '@admin/services/ShopTempService'
import flatry from '@admin/utils/flatry'
import PlaceholderForm from '@core/components/Placeholder/PlaceholderForm'

export default {
  components: { PlaceholderForm, ShopForm },
  data () {
    return {
      pageTitle: '新建素材',
      product: {
        product_name: '',
        print: ['print_cf', 'print_bq', 'print_xp']
      }
    }
  },
  methods: {
    async createProduct (product, success, callback) {
      const { data } = await flatry(ShopTempService.create(product))

      if (data) {
        this.$message.success(data.msg)
        this.$router.replace({ path: '/shop-temp' })
        success()
      }

      callback()
    }
  }
}
</script>

<style scoped>

</style>
