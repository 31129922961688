import Vue from 'vue'

const ShopService = {

  all (query) {
    return Vue.http.get('shop-temp/index', query)
  },

  delete (id) {
    return Vue.http.delete('shop-temp/delete', id)
  },

  edit (id, shop = null) {
    const endpoint = 'shop-temp/update'

    if (shop === null) {
      return Vue.http.get(endpoint, {
        id: id
      })
    }

    return Vue.http.put(endpoint, shop, {
      id: id
    })
  },

  create (shop = null, parentId = 0) {
    const endpoint = 'shop-temp/create'

    if (shop === null) {
      return Vue.http.get(endpoint, {
        parentId: parentId
      })
    }

    return Vue.http.post(endpoint, shop)
  },
  peiZhi (id, shop = null) {
    const endpoint = 'shop-temp/pei-zhi'

    if (shop === null) {
      return Vue.http.get(endpoint, {
        id: id
      })
    }

    return Vue.http.post(endpoint, shop, {
      id: id
    })
  },
  changeWm (wm) {
    return Vue.http.post('shop-temp/wm', wm)
  }
}

export default ShopService
