import { render, staticRenderFns } from "./_EditForm.vue?vue&type=template&id=8882cd98&scoped=true&"
import script from "./_EditForm.vue?vue&type=script&lang=js&"
export * from "./_EditForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8882cd98",
  null
  
)

export default component.exports