<template>
    <el-form
    v-if="formModel"
    :rules="formRules"
    :model="formModel"
    ref="formModel"
    label-width="100px"
    label-suffix="："
    @submit.native.stop.prevent="handleFormSubmit('formModel')"
  >
    <el-form-item
      label="名称"
      prop="product_name"
    >
      <el-col :md="9">
        <el-input
          type="text"
          v-model.trim="formModel.product_name"
        ></el-input>
      </el-col>
    </el-form-item>
    <el-form-item
    label="分类"
    prop="classifySelectArr">
     <el-col :md="12">
      <el-select style="width:100%" v-model="classifySelectArr" multiple placeholder="请选择">
        <el-option
          v-for="(item,index) in classifyArr"
          :key="index"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
     </el-col>
    </el-form-item>
    <el-form-item
      label="成份"
      prop="product_component"
    >
      <el-col :md="9">
        <el-input
          type="text"
          v-model.trim="formModel.product_component"
        ></el-input>
      </el-col>
    </el-form-item>
    <el-form-item
      label="描述"
      prop="product_describe"
    >
      <el-col :md="9">
        <el-input type="textarea" :rows="4"  v-model.trim="formModel.product_describe"
         placeholder=""></el-input>
      </el-col>
    </el-form-item>

    <el-form-item label="允许打印设置">
      <el-checkbox-group v-model="formModel.print">
        <el-checkbox label="print_cf">厨房打印</el-checkbox>
        <el-checkbox label="print_xp">小票打印</el-checkbox>
        <el-checkbox label="print_bq">标签打印</el-checkbox>
      </el-checkbox-group>
    </el-form-item>

    <div style="display:flex;">
    <el-form-item
      label="列表图"
      prop="list_map"
    >
      <list-photo
        :avatar="formModel.list_map"
        @on-upload-success="handleListUploadSuccess"
      ></list-photo>
    </el-form-item>
    <el-form-item
      label="详情图"
      prop="cover_map"
    >
      <view-photo
        :avatar="formModel.cover_map"
        @on-upload-success="handleViewUploadSuccess"
      ></view-photo>
    </el-form-item>
    </div>
    <el-form-item
    label="规格"
    prop="flavorSelectArr">
      <el-col :md="12">
      <el-select style="width:100%" v-model="flavorSelectArr" multiple placeholder="请选择">
        <el-option
          v-for="(item,index) in flavorArr"
          :key="index"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
     </el-col>
    </el-form-item>

    <el-form-item label="是否外卖">
      <el-switch
          v-model="formModel.is_wm"
          inactive-color="#ddd"
          :active-text="formModel.is_wm == 1?'是':'否'"
      >
      </el-switch>
    </el-form-item>

    <el-form-item>
      <el-button
        size="medium"
        type="primary"
        native-type="submit"
        :loading="submitLoading"
      >{{ submitText }}</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import Uploader from '@admin/components/uploaders/Uploader'
import ShopTempService from '@admin/services/ShopTempService'
import flatry from '@admin/utils/flatry'

export default {
  name: 'ProductForm',
  components: { listPhoto: Uploader, viewPhoto: Uploader },
  props: {
    submitText: {
      type: String,
      required: true
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    product: {
      type: Object
    }
  },
  data () {
    return {
      classifyArr: [], // 所有的分类选项
      classifySelectArr: [], // 当前选择的分类
      flavorArr: [],
      flavorSelectArr: [],
      submitLoading: false,
      print: ['print_cf', 'print_bq', 'print_xp'],
      formRules: {
        product_name: [
          {
            required: true,
            message: '请输入商品名称',
            trigger: 'blur'
          }
        ],
        product_describe: [
          {
            required: true,
            message: '请输入描述',
            trigger: 'blur'
          }
        ],
        product_component: [
          {
            required: true,
            message: '商品描述不能为空',
            trigger: 'blur'
          }
        ]
        // cover_map: [
        //   {
        //     required: true,
        //     message: '详情图不能为空',
        //     trigger: 'blur'
        //   }
        // ],
        // list_map: [
        //   {
        //     required: true,
        //     message: '列表不能为空',
        //     trigger: 'blur'
        //   }
        // ]
      },
      formModel: null
    }
  },
  async created () {
    const { data } = await flatry(ShopTempService.create())

    if (data) {
      this.flavorArr = data.data.flavor
      this.classifyArr = data.data.classify
    }
  },
  async mounted () {
    this.formModel = Object.assign(this.product)
    this.classifySelectArr = this.product.classifySelectArr
    this.flavorSelectArr = this.product.flavorSelectArr
  },
  methods: {
    handleViewUploadSuccess (avatarUrl) {
      this.formModel.cover_map = avatarUrl
    },
    handleListUploadSuccess (avatarUrl) {
      this.formModel.list_map = avatarUrl
    },
    handleFormSubmit (formName) {
      this.formModel.flavorSelectArr = this.flavorSelectArr
      this.formModel.classifySelectArr = this.classifySelectArr
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return false
        }

        this.submitLoading = true

        this.$emit('on-submit', this.formModel, () => {
          this.$refs[formName].clearValidate()
        }, () => {
          this.submitLoading = false
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
