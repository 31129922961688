<template>
  <el-upload
    class="avatar-uploader"
    :disabled="uploadAction === ''"
    :action="uploadAction"
    :name="name"
    :data="uploadData"
    :accept="'image/*'"
    :show-file-list="false"
    :headers="headers"
    :before-upload="handleBeforeUpload"
    :on-success="handleSuccess"
    :on-error="handleError"
  >
    <img
      alt="Image"
      v-if="avatarUrl"
      :src="avatarUrl"
      class="avatar"
    />
    <i
      v-else
      class="el-icon-plus avatar-uploader-icon"
    ></i>
  </el-upload>
</template>

<script>
// import flatry from '@admin/utils/flatry'
import Auth from '@admin/utils/auth'

export default {
  name: 'Uploader',
  props: ['avatar'],
  data () {
    return {
      name: 'img',
      avatarUrl: this.avatar,
      uploadAction: '',
      uploadData: null,
      uploadFolder: '',
      uploadSizeLimit: 4096000,
      headers: {
        // 上传图片的请求头部
        'X-Access-Token': Auth.getAccessToken()
      }
    }
  },
  async mounted () {
    this.uploadAction = Auth.getHttpApiUrl() + 'upload/photo'
  },
  methods: {
    handleBeforeUpload (file) {
      if (file.size > this.uploadSizeLimit) {
        this.$message({
          type: 'error',
          message: '你选择的文件大小超出上传限制',
          duration: 1500
        })

        return false
      }

      return true
    },
    handleSuccess (response) {
      if (response.success === true) {
        this.avatarUrl = response.data

        this.$emit('on-upload-success', this.avatarUrl)
      }
    },
    handleError (error) {
      console.info(error)
      let rg = /"msg":"(.*)","data"/ig
      const msg = rg.exec(error)
      this.$message.error(msg[1] || '上传失败:(')
    }
  }
}
</script>
<style lang="scss">
.avatar-uploader {
    .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 3px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .el-upload:hover {
        border-color: #409eff;
    }

    .avatar-uploader-icon {
        font-size: 16px;
        color: #8c939d;
        width: 90px;
        height: 90px;
        line-height: 90px;
        text-align: center;
    }

    .avatar {
        width: 90px;
        height: 90px;
        display: block;
        object-fit: cover;
    }
}
</style>
